<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full" id="v-step-0">
        <div class="domain-info mb-8">
          <!-- Search Term -->
          <search-filter  :searchTermValue="searchTerm" @input="(newsearchTerm) => {searchTerm = newsearchTerm}"></search-filter>
          <!-- Within -->
          <select-filter
            labelname="Within"
            :options="withinOptions"
            optionsName="withinName"
            :value="withinSelected"
            @input="(newWithin) => {withinSelected = newWithin}"
          ></select-filter>
          <!-- Date Picker -->
          <date-range-picker
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="(newFromDate) => {fromDate = newFromDate}"
            @inputToDate="(newToDate) => {toDate = newToDate}"
          ></date-range-picker>
          <!-- Only Companies checkbox -->
          <only-company
            :onlyCompaniesValue="onlyCompanies"
            @input="(newonlyCompanies) => {onlyCompanies = newonlyCompanies}"
          ></only-company>
          <!-- Update Data -->
          <vs-button type="filled" @click="getSearchLeadList()" class="small-btn bg-actionbutton">{{ LabelConstant.buttonLabelRunQuery }}</vs-button>
          
        </div>
      </div>
    </div>
    <!-- Search Leads Table -->
    <vx-card class="main-search-card">

      <!-- Table Title -->
      <div class="table-title">
         <h2>Search Leads</h2>
         <div class="actions-button">
          <!-- <vs-button type="filled" @click="connectionPopup=true" class="small-btn bg-actionbutton">Export</vs-button> -->
          <!-- <vx-tooltip color="primary" text="Export" class="float-left">
          <vs-button
               
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-download"
                class="mr-4 float-left w-auto"
                @click="connectionPopup=true"
                >{{ LabelConstant.buttonLabelExport }}</vs-button
              >
              </vx-tooltip> -->
            <vx-tooltip color="actionbutton" text="CSV" class="float-left">
               <vs-button class="bg-actionbutton " type="filled" text="CSV" @click="downloadCSV()">
               <img src="../../assets/images/csv.svg">
               </vs-button>
            </vx-tooltip>
            <vx-tooltip color="actionbutton" type="filled"  text="EXCEL" class="float-left ml-4">
               <vs-button class="bg-actionbutton" type="filled" @click="downloadExcel()">
               <img src="../../assets/images/excel.svg">
               </vs-button>
            </vx-tooltip>
         </div>
      </div>
      <div class="common-table">      
         <!--Table Select Row-->
         <div class="table-row-select lead-select">
            <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
               <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in recordList" />
            </vs-select>
        </div>
        <vs-table class="search-leads" ref="serachLeadTableData" :data="searchLeads" search :max-items="perPage" pagination>
          <template slot="thead">
            <vs-th class="sticky-item sticky-item-1"></vs-th>
            <vs-th class="sticky-item sticky-item-2" sort-key="sortableDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
            <vs-th class="sticky-item sticky-item-3" sort-key="comp">{{ LabelConstant.tableHeaderLabelCompanyName }}</vs-th>
            <vs-th sort-key="cntry">{{ LabelConstant.tableHeaderLabelCountry }}</vs-th>
            <vs-th sort-key="st">{{ LabelConstant.tableHeaderLabelState }}</vs-th>
            <vs-th sort-key="city">{{ LabelConstant.tableHeaderLabelCity }}</vs-th>
            <vs-th sort-key="src">{{ LabelConstant.tableHeaderLabelSource }}</vs-th>
            <vs-th sort-key="keyw">{{ LabelConstant.tableHeaderLabelKeywords }}</vs-th>
            <vs-th sort-key="vtype">{{ LabelConstant.tableHeaderLabelType }}</vs-th>
            <vs-th sort-key="site">{{ LabelConstant.tableHeaderLabelSite }}</vs-th>
            <vs-th sort-key="cName">{{ LabelConstant.tableHeaderLabelCampaignName }}</vs-th>
            <vs-th sort-key="cMed">{{ LabelConstant.tableHeaderLabelCampaignMedium }}</vs-th>
            <vs-th sort-key="cTerm">{{ LabelConstant.tableHeaderLabelCampaignTerm }}</vs-th>
            <vs-th sort-key="cCont">{{ LabelConstant.tableHeaderLabelCampaignContent }}</vs-th>
            <vs-th sort-key="cSrc">{{ LabelConstant.tableHeaderLabelCampaignSource }}</vs-th>
            <vs-th sort-key="email">{{ LabelConstant.tableHeaderLabelEmail }}</vs-th>
            <vs-th sort-key="ip">{{ LabelConstant.tableHeaderLabelIPAddress }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="sticky-item sticky-item-1">
                <vs-button
                  title="View Details"
                  @click="detailspopupActive=true, getCompanyDetails(tr.Id)"                
                  type="filled"
                  icon-pack="feather"
                  icon="icon-search"
                  class="mr-0"
                  color="primary"
                ></vs-button>
              </vs-td>
              <vs-td class="sticky-item sticky-item-2" :data="tr.sortableDate">{{tr.dCreated}}</vs-td>
              <vs-td class="sticky-item sticky-item-3" :data="tr.comp">{{tr.comp}}</vs-td>
              <vs-td :data="tr.cntry">{{tr.cntry}}</vs-td>
              <vs-td :data="tr.st">{{tr.st}}</vs-td>
              <vs-td :data="tr.city">{{tr.city}}</vs-td>
              <vs-td :data="tr.src">{{tr.src}}</vs-td>
              <vs-td :data="tr.keyw">{{tr.keyw}}</vs-td>
              <vs-td :data="tr.vtype">{{tr.vtype}}</vs-td>
              <vs-td :data="tr.site">{{tr.site}}</vs-td>
              <vs-td :data="tr.cName">{{tr.cName}}</vs-td>
              <vs-td :data="tr.cMed">{{tr.cMed}}</vs-td>
              <vs-td :data="tr.cTerm">{{tr.cTerm}}</vs-td>
              <vs-td :data="tr.cCont">{{tr.cCont}}</vs-td>
              <vs-td :data="tr.cSrc">{{tr.cSrc}}</vs-td>
              <vs-td :data="tr.email">{{tr.email}}</vs-td>
              <vs-td :data="tr.ip">{{tr.ip}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{searchLeads.length|tableRecordNumberFormatter}} entries</p>
        <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{searchLeads.length|tableRecordNumberFormatter}} entries)</p>
        <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
      </div>
            
      <!--<vs-table class="table-search-design" :data="searchLeads" search :max-items="perPage" pagination>
        <tr class="main-tr">
          <td>
            <table>
              <thead>
                <tr>
                  <th class="width-0"><vs-button title="View Details" @click="detailspopupActive=true, getCompanyDetails(tr.Id)"
                     type="filled" icon-pack="feather" icon="icon-search" class="mr-0 bg-actionbutton"></vs-button></th>
                  <th class="width-1">Date Created</th>
                  <th class="width-2">Company Name</th>
                  <th class="width-3">Country</th>
                  <th class="width-4">State</th>
                  <th class="width-5">City</th>
                  <th class="width-6">Source</th>
                  <th class="width-7">Keywords</th>                                 
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="width-0">
                    
                  </td>
                  <td class="width-1">12/29/2019 4:17:13 PM</td>
                  <td class="width-2">Huawei Clouds</td>
                  <td class="width-3">HK</td>
                  <td class="width-4">Magnisia</td>
                  <td class="width-5">Vólos</td>
                  <td class="width-6">sourceforge.net</td>
                  <td class="width-7">magnet link downloader</td>                              
                </tr>
              </tbody>
            </table> 
            <table>
              <thead>
                <tr>
                  <th class="width-0">Type</th>
                  <th class="width-1">Site</th>
                  <th class="width-2">Campaign Name</th>
                  <th class="width-3">Campaign Medium</th>
                  <th class="width-4">Campaign Term</th>
                  <th class="width-5">Campaign Content</th>
                  <th class="width-6">Email</th>
                  <th class="width-7">IP Address</th>        
                            
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="width-0">Company</td>
                  <td class="width-1"><span>www.visualvisitor.com</span></td>
                  <td class="width-2">fridayc</td>
                  <td class="width-3">email</td>
                  <td class="width-4">123</td>
                  <td class="width-5">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum dolor</td>
                  <td class="width-6"><span>ank.sureka@gmail.com</span></td>
                  <td class="width-7">162.249.177.124</td>                  
                </tr>
              </tbody>
            </table>            
          </td>
        </tr>
        <tr class="main-tr">
          <td>
            <table>
              <thead>
                <tr>
                  <th class="width-0"><vs-button title="View Details" @click="detailspopupActive=true, getCompanyDetails(tr.Id)"
                    type="filled" icon-pack="feather" icon="icon-search" class="mr-0 bg-actionbutton"></vs-button></th>
                  <th class="width-1">Date Created</th>
                  <th class="width-2">Company Name</th>
                  <th class="width-3">Country</th>
                  <th class="width-4">State</th>
                  <th class="width-5">City</th>
                  <th class="width-6">Source</th>
                  <th class="width-7">Keywords</th>                                 
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="width-0">
                    
                  </td>
                  <td class="width-1">12/29/2019 4:17:13 PM</td>
                  <td class="width-2">Huawei Clouds</td>
                  <td class="width-3">HK</td>
                  <td class="width-4">Magnisia</td>
                  <td class="width-5">Vólos</td>
                  <td class="width-6">sourceforge.net</td>
                  <td class="width-7">magnet link downloader</td>                              
                </tr>
              </tbody>
            </table> 
            <table>
              <thead>
                <tr>
                  <th class="width-0">Type</th>
                  <th class="width-1">Site</th>
                  <th class="width-2">Campaign Name</th>
                  <th class="width-3">Campaign Medium</th>
                  <th class="width-4">Campaign Term</th>
                  <th class="width-5">Campaign Content</th>
                  <th class="width-6">Email</th>
                  <th class="width-7">IP Address</th>        
                            
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="width-0">Company</td>
                  <td class="width-1"><span>www.visualvisitor.com</span></td>
                  <td class="width-2">fridayc</td>
                  <td class="width-3">email</td>
                  <td class="width-4">123</td>
                  <td class="width-5">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum dolor</td>
                  <td class="width-6"><span>ank.sureka@gmail.com</span></td>
                  <td class="width-7">162.249.177.124</td>                  
                </tr>
              </tbody>
            </table>            
          </td>
        </tr>
        <tr class="main-tr">
          <td>
            <table>
              <thead>
                <tr>
                  <th class="width-0"><vs-button title="View Details" @click="detailspopupActive=true, getCompanyDetails(tr.Id)"
                    type="filled" icon-pack="feather" icon="icon-search" class="mr-0 bg-actionbutton"></vs-button></th>
                  <th class="width-1">Date Created</th>
                  <th class="width-2">Company Name</th>
                  <th class="width-3">Country</th>
                  <th class="width-4">State</th>
                  <th class="width-5">City</th>
                  <th class="width-6">Source</th>
                  <th class="width-7">Keywords</th>                                 
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="width-0">
                    
                  </td>
                  <td class="width-1">12/29/2019 4:17:13 PM</td>
                  <td class="width-2">Huawei Clouds</td>
                  <td class="width-3">HK</td>
                  <td class="width-4">Magnisia</td>
                  <td class="width-5">Vólos</td>
                  <td class="width-6">sourceforge.net</td>
                  <td class="width-7">magnet link downloader</td>                              
                </tr>
              </tbody>
            </table> 
            <table>
              <thead>
                <tr>
                  <th class="width-0">Type</th>
                  <th class="width-1">Site</th>
                  <th class="width-2">Campaign Name</th>
                  <th class="width-3">Campaign Medium</th>
                  <th class="width-4">Campaign Term</th>
                  <th class="width-5">Campaign Content</th>
                  <th class="width-6">Email</th>
                  <th class="width-7">IP Address</th>        
                            
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="width-0">Company</td>
                  <td class="width-1"><span>www.visualvisitor.com</span></td>
                  <td class="width-2">fridayc</td>
                  <td class="width-3">email</td>
                  <td class="width-4">123</td>
                  <td class="width-5">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum dolor</td>
                  <td class="width-6"><span>ank.sureka@gmail.com</span></td>
                  <td class="width-7">162.249.177.124</td>                  
                </tr>
              </tbody>
            </table>            
          </td>
        </tr>
      </vs-table> -->

    </vx-card>
    <find-any-one-select-connection :popupTitle="'Select Connection'" :connectionPopup="connectionPopup" :pushModule="'searchLead'" :selectedMain="searchLeads" :selectedInner="[]" :applicationId="3"  @cancelConnection="(connectionPopup=false,selectedMain=[])" ></find-any-one-select-connection>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
    <!-- Company Details popup -->
    <company-details-modal
      :latestVisitorId="latestVisitorId"
      :searchLeadDetailsData="searchLeadDetails"
      @inputSearchLeadDetails="(newSearchLeadDetails) => {searchLeadDetails = newSearchLeadDetails}" 
      :pageViewsData="pageViewsCompany" 
      @inputPageViews="(newpageViewsCompany) => {pageViewsCompany = newpageViewsCompany}" 
      :keywordsData="keywords" 
      @inputKeywords="(newKeywords) => {keywords = newKeywords}" 
      :allVisitorUniqueDetailShortData="allVisitorUniqueDetailShort" 
      @inputAllVisitorUniqueDetailShort="(newAllVisitorUniqueDetailShort) => {allVisitorUniqueDetailShort = newAllVisitorUniqueDetailShort}" 
      :detailspopupActive='detailspopupActive' 
      @close="closepopup"
      @getCompanyDetails="getCompanyDetails(latestVisitorId)">
    </company-details-modal>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
//import Cookie from "js-cookie";


/* Filter */
import SearchFilter from "../../components/filter/SearchFilter";
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import HelpModal from "../../components/modal/HelpModal";
import CompanyDetailsModal from "../../components/modal/CompanyDetailsModal";
import FindAnyOneSelectConnection from "../../components/modal/FindAnyOneSelectConnection.vue"

export default {
  components: {
    VxCard,
    SearchFilter,
    SelectFilter,
    DateRangePicker,
    OnlyCompany,
    HelpModal,
    CompanyDetailsModal,
    FindAnyOneSelectConnection
  },
  data() {
    return {
      /*Help Popup */
      popupActive: false,

      //Search Term
      searchTerm: "",

      /* Filter Select */
      withinSelected: { withinName: "Company" },
      //Within dropdown options
      withinOptions: [
        { withinName: "Company" },
        { withinName: "Country" },
        { withinName: "State" },
        { withinName: "City" },
        { withinName: "URL" },
        { withinName: "Referral Sources" },
        { withinName: "Keywords Searched" },
        { withinName: "Email" },
        { withinName: "IP Address" }
      ],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      //Only Companies
      onlyCompanies: true,

      //Search Leads Table data
      searchLeads: [],
     
      perPage: 5,
      recordList: [
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: '100', value: 100}
			],
      
      //Company details popup
      detailspopupActive:false,
      latestVisitorId: "",
      searchLeadDetails: {},
      allVisitorUniqueDetailShort: [],
      pageViewsCompany: {},
      keywords: [],

      /* More Details serach lead */
      active: false,

      connectionPopup: false,

    };
  },
  mounted(){
  },
  created() {
    this.setFromDate();
    this.setToDate();
    this.setQueryStringParams()

    this.getSearchLeadList()
  },
  methods: {
    /* Popup Close */
    closepopup() {
      (this.popupActive = false), (this.detailspopupActive = false);
    },
    //Set Qurery String Params
    setQueryStringParams(){
      if(this.$route.query.keyword && this.$route.query.dateStart && this.$route.query.dateEnd){
        this.fromDate = this.$route.query.dateStart
        this.toDate = this.$route.query.dateEnd
        this.onlyCompanies = this.$route.query.onlyCompanies
        this.searchTerm = this.$route.query.keyword
        this.withinSelected = { withinName: "Keywords Searched" }
      }
    },
    //Get latest visitor company details
    getCompanyDetails(id){
      this.latestVisitorId = id
      this.getSearchLeadDetail(id)
      this.getAllVisitorUniqueDetailShort(id)
      this.getPageViews(id)
      this.getKeywords(id)
    },

    //Get Search Leads
    getSearchLeadList(){
      let getSearchLeadListParams = {
        Term: this.searchTerm,
        Within: this.withinSelected.withinName,
        Date_Start: this.fromDate,
        Date_End: this.toDate,
        Only_Companies: this.onlyCompanies
      }

      this.$vs.loading()
      this.axios.post("/ws/SearchLead/GetSearchLeadList", getSearchLeadListParams).then(response => {
        let data = response.data;
        this.searchLeads = data
        this.$refs.serachLeadTableData.currentx = 1
        this.$refs.serachLeadTableData.searchx = ""
        this.searchLeads.map(function(manipulatedData){
          manipulatedData.sortableDate = new Date(manipulatedData.dCreated);
          manipulatedData.cntry = manipulatedData.cntry == null ? "" : manipulatedData.cntry
          manipulatedData.st = manipulatedData.st == null ? "" : manipulatedData.st
          manipulatedData.city = manipulatedData.city == null ? "" : manipulatedData.city
          manipulatedData.src = manipulatedData.src == null ? "" : manipulatedData.src
          manipulatedData.keyw = manipulatedData.keyw == null ? "" : manipulatedData.keyw
          manipulatedData.vtype = manipulatedData.vtype == null ? "" : manipulatedData.vtype
          manipulatedData.site = manipulatedData.site == null ? "" : manipulatedData.site
          manipulatedData.cName = manipulatedData.cName == null ? "" : manipulatedData.cName
          manipulatedData.cMed = manipulatedData.cMed == null ? "" : manipulatedData.cMed
          manipulatedData.cTerm = manipulatedData.cTerm == null ? "" : manipulatedData.cTerm
          manipulatedData.cCont = manipulatedData.cCont == null ? "" : manipulatedData.cCont
          manipulatedData.cSrc = manipulatedData.cSrc == null ? "" : manipulatedData.cSrc
          manipulatedData.email = manipulatedData.email == null ? "" : manipulatedData.email
          manipulatedData.ip = manipulatedData.ip == null ? "" : manipulatedData.ip
					return manipulatedData;
				});
        
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get latest visitor popup details
    getSearchLeadDetail(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetSearchLeadDetail?id="+id).then(response => {
        let data = response.data;
        this.searchLeadDetails = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get latest visitor popup details
    getAllVisitorUniqueDetailShort(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetAllVisitorUniqueDetailShort?id="+id).then(response => {
        let data = response.data;
        this.allVisitorUniqueDetailShort = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get latest visitor popup details
    getPageViews(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetPageViews?id="+id).then(response => {
        let data = response.data;
        this.pageViewsCompany = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get latest visitor popup details
    getKeywords(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetKeywords?id="+id).then(response => {
        let data = response.data;
        this.keywords = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Download CSV file
    downloadCSV(){
      let fileName = 'Search_Leads_Export.csv'
      this.axios.get('/ws/SearchLead/GetSearchLeadListExport?Term='+this.searchTerm+'&Within='+this.withinSelected.withinName+'&Date_Start='+this.fromDate+'&Date_End='+this.toDate+'&Only_Companies='+this.onlyCompanies+'&FileType=csv', {
          responseType: 'blob'
      }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'text/csv'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
      });
    },
    //Download Excel file
    downloadExcel(){
      let fileName = 'Search_Leads_Export.xlsx'
      this.axios.get('/ws/SearchLead/GetSearchLeadListExport?Term='+this.searchTerm+'&Within='+this.withinSelected.withinName+'&Date_Start='+this.fromDate+'&Date_End='+this.toDate+'&Only_Companies='+this.onlyCompanies+'&FileType=excel', {
          responseType: 'blob'
      }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
      });
    }
  },
  computed: {
    /**
		 * Showing Entries details
		 * isTableRecordEntriesDisplay()
		 * isSearch()
		 * showingDataFrom()
		 * showingDataTo()
		 * totalSearchedData()
     * showingSearchedDataTo()
		 *  */
		isTableRecordEntriesDisplay(){
			if(this.searchLeads.length > 0){
				if(this.$refs.serachLeadTableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
      if(this.searchLeads.length > 0){
        if(this.$refs.serachLeadTableData.searchx == null || this.$refs.serachLeadTableData.searchx == ""){
            return false
        }else{
            return true
        }
      }
    },
    showingDataFrom: function () {
      if(this.searchLeads.length > 0){
        return ((this.$refs.serachLeadTableData.currentx - 1) * this.perPage) + 1 
      }
    },
    showingDataTo: function () {
      if(this.searchLeads.length > 0){
        let totalPages = this.$refs.serachLeadTableData.getTotalPages
        if(totalPages == this.$refs.serachLeadTableData.currentx){
            return ((this.$refs.serachLeadTableData.currentx - 1) * this.perPage) + this.$refs.serachLeadTableData.datax.length
        }else{
            return ((this.$refs.serachLeadTableData.currentx) * this.perPage)
        }
      }
    },
    totalSearchedData: function () {
      if(this.searchLeads.length > 0){
        return this.$refs.serachLeadTableData.queriedResults.length
      }
    },
    showingSearchedDataTo: function () {
      if(this.searchLeads.length > 0){
        let totalPages = this.$refs.serachLeadTableData.getTotalPagesSearch
        if(totalPages == this.$refs.serachLeadTableData.currentx){
            return ((this.$refs.serachLeadTableData.currentx - 1) * this.perPage) + this.$refs.serachLeadTableData.datax.length
        }else{
            return ((this.$refs.serachLeadTableData.currentx) * this.perPage)
        }
      }
    }
   }
};
</script>
